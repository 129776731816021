<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2 class="center">Kurumsal Hizmetler</h2>

        <!-- <div
          class="videoplay"
          @click="
            SET_MODAL('ModalVideoKurumsal');
            SET_NOTIFY_REQUESTER_TYPE(0);
          "
        >
          <div>
            <img class="playthumb" src="@/assets/img/video_thumb_03.png" alt />
            <img class="playbtn" src="@/assets/img/video_play.svg" alt />
          </div>
        </div>

        <div class="staticbox video">
          <div class="p70">
            <h3>Hizmet sağlayıcılarımız için tanıtım filmimizi izleyin</h3>
            <p>
              İleti Yönetim Sistemi hakkında bilgi edinmek için tanıtım
              filmimizi izleyin.
            </p>
          </div>
        </div> -->

        <social />

        <div class="staticbox">
          <div>
            <div class="madde">
              <p>
                Tüm
                <router-link to="/hizmet-saglayicilar" class="blue"
                  ><strong>hizmet sağlayıcılar</strong></router-link
                >
                İYS’yi Temel Hizmetler kapsamında mevzuatın zorunlu kıldığı tüm
                işlevler için ücretsiz olarak kullanabilirler.
              </p>

              <p>
                Kurumsal hizmetlerimiz, kendi bilişim sistemleriyle İYS arasında
                veri aktarımını ve eşgüdümünü otomasyon yoluyla yapmak
                (Entegrasyon Modülü) isteyen hizmet sağlayıcılara sunulmaktadır.
                Yine dileyen hizmet sağlayıcılar, “ViA Modülü” kullanarak izin
                onay ve ret işlemlerini İYS üzerinde gerçekleştirebilir. Bu
                durumda, işleme ilişkin ispat yükümlülüğü hizmet sağlayıcıdan
                kalkar.
              </p>

              <p>
                Entegrasyon hizmeti alabileceğiniz bir
                <router-link to="/is-ortaklari" class="blue"
                  ><strong
                    >iş ortağına buradan ulaşabilirsiniz</strong
                  ></router-link
                >.
              </p>

              <p>
                İLETİ paketlerine ilişkin kurumsal hizmet tablosu ve ücretleri
                aşağıda yer almaktadır. İLETİ paketlerinde hizmet sağlayıcı,
                entegrasyonunu ancak bir iş ortağı üzerinden gerçekleştirebilir.
              </p>
            </div>
          </div>

          <!-- <div class="full">
            <img src="@/assets/img/icons/temel-01.svg" alt />
          </div> -->
        </div>

        <h2 id="paketler" class="center">İLETİ Paketleri</h2>
        <!-- <h1>Hizmetler ve Paketler</h1> -->

        <div class="staticbox">
          <div class>
            <div class="scroller">
              <!-- <table class="fiyatlar">
                <thead>
                  <tr>
                    <th class="paket">Paketler</th>
                    <th>Adres Sayısı</th>
                    <th>Seviye</th>
                    <th class="tick">İzin Ekleme / Değiştirme</th>
                    <th class="tick">İzin Sorgulama</th>
                    <th class="tick">Günlük Raporlama</th>
                    <th class="tick">Marka ve Bayi Yönetimi</th>
                    <th class="tick">Entegrasyon Modülü</th>
                    <th class="tick">İYS Yolu Modülü</th>
                    <th>Fiyatlar* (Yıllık)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="paket">Temel Hizmetler</td>
                    <td>Sınırsız</td>
                    <td>-</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <td class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="paket">İLETİ - 5</td>
                    <td rowspan="2">0 - 5.000</td>
                    <td>Seviye 1</td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <tr>
                    <td>Seviye 2</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="paket">İLETİ - 25</td>
                    <td rowspan="2">5.001 - 25.000</td>
                    <td>Seviye 1</td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <td class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺1.020</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="paket">İLETİ - 75</td>
                    <td rowspan="2">25.001 - 75.000</td>
                    <td>Seviye 1</td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <td class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺3.300</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="paket">İLETİ - 150</td>
                    <td rowspan="2">75.001 - 150.000</td>
                    <td>Seviye 1</td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <td class="ucretli">₺4.320</td>
                  </tr>
                  <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺7.200</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="paket">İLETİ - 250</td>
                    <td rowspan="2">150.001 - 250.000</td>
                    <td>Seviye 1</td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td rowspan="2" class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <td class="ucretli">₺4.920</td>
                  </tr>
                  <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺8.200</td>
                  </tr>
                </tbody>
              </table> -->

              <table class="fiyatlar">
                <thead>
                  <tr>
                    <th class="paket">Paket adı</th>
                    <th>Adres Sayısı*</th>
                    <!-- <th>Seviye</th> -->
                    <th class="tick">İzin Ekleme / Değiştirme</th>
                    <th class="tick">İzin Sorgulama</th>
                    <th class="tick">Günlük Raporlama</th>
                    <th class="tick">Marka ve Bayi Yönetimi</th>
                    <th class="tick">Entegrasyon Modülü</th>
                    <!-- <th class="tick">İYS Yolu Modülü</th> -->
                    <th>Fiyatlar** (Yıllık)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="paket">Temel Hizmetler</td>
                    <td>Sınırsız</td>
                    <!-- <td>-</td> -->
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="nooption">✘</span>
                    </td>
                    <!-- <td class="tick">
                      <span class="nooption">✘</span>
                    </td> -->
                    <td class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <tr>
                    <td class="paket">İLETİ - 5</td>
                    <td>0 - 5.000</td>
                    <!-- <td>Seviye 1</td> -->
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <!-- <td  class="tick">
                      <span class="option">✔</span>
                    </td> -->
                    <td class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <!-- <tr>
                    <td>Seviye 2</td>
                  </tr> -->
                  <tr>
                    <td class="paket">İLETİ - 25</td>
                    <td>5.001 - 25.000</td>
                    <!-- <td>Seviye 1</td> -->
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <!-- <td class="tick">
                      <span class="nooption">✘</span>
                    </td> -->
                    <td class="ucretsiz">Ücretsiz</td>
                  </tr>
                  <!-- <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺1.020</td>
                  </tr> -->
                  <tr>
                    <td class="paket">İLETİ - 75</td>
                    <td>25.001 - 75.000</td>
                    <!-- <td>Seviye 1</td> -->
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <!-- <td class="tick">
                      <span class="nooption">✘</span>
                    </td> -->
                    <td class="ucretli">₺10.450</td>
                  </tr>
                  <!-- <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺3.300</td>
                  </tr> -->
                  <tr>
                    <td class="paket">İLETİ - 150</td>
                    <td>75.001 - 150.000</td>
                    <!-- <td>Seviye 1</td> -->
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <!-- <td class="tick">
                      <span class="nooption">✘</span>
                    </td> -->
                    <td class="ucretli">₺21.000</td>
                  </tr>
                  <!-- <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺7.200</td>
                  </tr> -->
                  <tr>
                    <td class="paket">İLETİ - 250</td>
                    <td>150.001 - 250.000</td>
                    <!-- <td>Seviye 1</td> -->
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <!-- <td class="tick">
                      <span class="nooption">✘</span>
                    </td> -->
                    <td class="ucretli">₺24.600</td>
                  </tr>
                  <!-- <tr>
                    <td>Seviye 2</td>
                    <td class="tick">
                      <span class="option">✔</span>
                    </td>
                    <td class="ucretli">₺8.200</td>
                  </tr> -->
                </tbody>
              </table>

              <small
                >*Adres Sayısı (onaylı elektronik iletişim adresi sayısı),
                izinleriniz içinde onaylı olarak yer alan toplam telefon
                numarası sayısı ile elektronik posta adresi sayısından büyük
                olanı belirlenerek hesaplanır.</small
              >

              <br />
              <br />

              <small>**Tüm fiyatlar KDV hariçtir.</small>
            </div>

            <p class="note">
              250.000 üzerinde adres sayısı olan hizmet sağlayıcılar
              <router-link class="blue" to="/iletisim">buraya </router-link>
              tıklayarak bizimle iletişime geçebilir.
            </p>
            <!-- <p class="note">
              250.000 üzerinde adres sayısı olanlar veya Seviye-1 hizmetlerine
              ek olarak ViA, gelişmiş raporlama, kapsamlı bayi yönetimi,
              çoklu alt kullanıcı oluşturma ve rol tanımlaması yapabilme gibi
              (Seviye-2) hizmetlerden faydalanmak isteyen hizmet sağlayıcılar
              <router-link class="blue" to="/iletisim">buraya </router-link>
              tıklayarak bizimle iletişime geçebilir.
            </p> -->
            <!--
            <p class="note"> Gelişmiş Marka, Bayi, Raporlama ve Hesap Yönetimi Modülleri'nin de yer aldığı 250.000 üzeri adres sayısına sahip
                <strong>İLETİ+ paketleri</strong> için <router-link class=" blue" to="/iletisim" >Bize Ulaşın</router-link>
            </p>-->

            <!-- <p>
              Adres Sayısı (onaylı elektronik iletişim adresi sayısı), veri
              içinde onaylı olarak yer alan toplam telefon (sabit ve mobil
              toplamı) sayısı ile elektronik posta adresi sayısından büyük olanı
              belirlenerek hesaplanır.
            </p> -->
          </div>
        </div>

        <div class="staticbox">
          <div class>
            <div>
              <h2>İLETİ ve İLETİ+ MODÜLLERİ</h2>
              <h3>Temel İleti Modülü</h3>
              <p>
                <router-link
                  class="ul blue"
                  to="/hizmet-saglayici/temel-hizmetler"
                  >Temel Hizmetler</router-link
                >
                kapsamında aşağıda listelenen tüm işlemler İYS web sitesi
                üzerinden elle gerçekleştirilir.
              </p>

              <ul>
                <li>İzin ekleme veya değiştirme</li>
                <li>İzin sorgulama</li>
                <li>Günlük raporlama</li>
                <li>Marka ve bayi yönetimi</li>
                <!-- <li>
                  30998 sayılı Resmî Gazete’de yayımlanan 4 Ocak 2020 tarihli
                  Yönetmelik’in Geçici Maddesi gereğince,
                  <br />30 Kasım 2020 tarihine kadar mevcut veri tabanını yükleme
                </li> -->
              </ul>

              <h3>Entegrasyon Modülü</h3>
              <p>
                Entegrasyon (API) modülü aşağıdaki servislere erişimi içerir.
                İletişim adresi sayısı 250.000’den az olan hizmet sağlayıcılar,
                İLETİ Hizmetleri’ne ancak yetkilendirdikleri iş ortakları
                üzerinden erişim sağlayabilirler.
              </p>

              <ul>
                <li>İzin ekleme veya değişiklik yapma servisi</li>
                <li>İzin sorgulama servisi</li>
                <li>İzin değişikliği durumunu bildiren servis</li>
                <li>Mutabakat servisi</li>
                <!-- <li>
                  30998 sayılı Resmî Gazete’de yayımlanan 4 Ocak 2020 tarihli
                  Yönetmelik’in Geçici Maddesi gereğince,
                  <br />30 Kasım 2020 tarihine kadar mevcut veri tabanını yükleme
                  servisi
                </li> -->
              </ul>

              <h3>ViA Modülü</h3>
              <p>
                ViA modülü hizmet sağlayıcının, mevzuata uygun biçimde, alıcının
                ticari elektronik ileti onayının veya reddinin İYS üzerinden
                alınmasını sağlar.
              </p>
              <!-- <p>
                İYS yolu modülü hizmet sağlayıcının, mevzuata uygun biçimde,
                alıcının ticari elektronik ileti onayının veya reddinin İYS
                üzerinden alınmasını sağlayan, aşağıdaki yollardan birini
                kullanmasıdır.
              </p> -->

              <!-- <ul>
                <li>İYS kısa mesaj numarası</li>
                <li>İYS kısa link</li>
                <li>İYS mobil uygulaması</li>
              </ul> -->

              <!-- <h2>İLETİ+ MODÜLLERİ</h2> -->

              <!-- <h3>Temel İleti Modülü</h3>
              <p>
                <router-link
                  class="ul blue"
                  to="/hizmet-saglayici/temel-hizmetler"
                  >Temel Hizmetler</router-link
                >
                kapsamında aşağıda listelenen tüm işlemler İYS web sitesi
                üzerinden elle gerçekleştirilir.
              </p> -->

              <!-- <ul>
                <li>İzin ekleme veya değiştirme</li>
                <li>İzin sorgulama</li>
                <li>Günlük raporlama</li>
                <li>Marka ve bayi yönetimi</li>
              </ul> -->

              <!-- <h3>Entegrasyon Modülü</h3>
              <p>
                Entegrasyon (API) modülü aşağıdaki servislere erişimi içerir.
                İletişim adresi sayısı 250.000’den az olan Hizmet sağlayıcılar,
                İLETİ Hizmetleri’ne ancak yetkilendirdikleri iş ortakları
                üzerinden erişim sağlayabilirler.
              </p>

              <ul>
                <li>İzin ekleme veya değişiklik yapma servisi</li>
                <li>İzin sorgulama servisi</li>
                <li>İzin değişikliği durumunu bildiren servis</li>
                <li>Mutabakat servisi</li>
              </ul> -->

              <!-- <h3>İYS Yolu Modülü</h3>
              <p>
                İYS yolu modülü hizmet sağlayıcının, mevzuata uygun biçimde,
                alıcının ticari elektronik ileti onayının veya reddinin İYS
                üzerinden alınmasını sağlayan, aşağıdaki yollardan birini
                kullanmasıdır.
              </p>

              <ul>
                <li>İYS kısa mesaj numarası</li>
                <li>İYS kısa link</li>
                <li>İYS çağrı merkezi/IVR</li>
                <li>İYS mobil uygulaması</li>
              </ul> -->

              <!-- <h3>Gelişmiş Raporlama Modülü</h3>
              <ul>
                <li>
                  İYS Web veya API entegrasyonu üzerinden farklı zaman
                  aralıkları ve filtrelere göre raporlar alabilme
                </li>
                <li>
                  İzin kanalı/izin kaynağı/marka/bayi/tarih bazında izin
                  raporları alabilme
                </li>
                <li>Diğer ek filtreleri raporlara uygulayabilme</li>
              </ul>

              <h3>Gelişmiş Marka Yönetimi Modülü</h3>
              <ul>
                <li>İYS Yolu için marka anahtar kelimesi tanımlayabilme</li>
                <li>Özel İYS Numarası talep edebilme</li>
                <li>İYS Web üzerinde markaya özel kurumsal web sayfası</li>
                <li>
                  Marka bazlı izin raporları alabilme (Gelişmiş Raporlama Modülü
                  ile birlikte)
                </li>
                <li>
                  Marka adına İYS kullanıcıları tanımlayabilme ve
                  yetkilendirebilme (Hesap Yönetimi Modülü ile birlikte)
                </li>
              </ul>

              <h3>Gelişmiş Bayi Yönetimi Modülü</h3>
              <ul>
                <li>
                  Hizmet sağlayıcının İYS Web veya API entegrasyonuyla markaları
                  altındaki bayilerine yetki bazında İLETİ+ Hizmetlerine erişim
                  sağlaması
                </li>
                <li>Bayiler için marka adına İYS Yolu’nu kullanabilme</li>
                <li>
                  Bayi bazlı izin raporlarını alabilme (Gelişmiş Raporlama
                  Modülü ile birlikte)
                </li>
                <li>
                  Bayi adına İYS Kullanıcıları tanımlayabilme (Hesap Yönetimi
                  Modülü ile birlikte)
                </li>
              </ul>

              <h3>Hesap Yönetimi Modülü</h3>
              <ul>
                <li>Alt kullanıcı hesapları oluşturabilme</li>
                <li>
                  API hesabı oluşturabilme (Entegrasyon Modülü ile birlikte)
                </li>
                <li>Kullanıcı bazlı rol ve yetki tanımlayabilme</li>
                <li>
                  Marka adına İYS kullanıcıları tanımlayabilme (Gelişmiş Marka
                  Yönetimi Modülü ile birlikte)
                </li>
                <li>
                  Bayi adına İYS kullanıcıları tanımlayabilme (Gelişmiş Bayi
                  Yönetimi Modülü ile birlikte)
                </li>
              </ul> -->
            </div>
          </div>

          <!-- <div class="full">
            <img src="@/assets/img/icons/hs-03.svg" alt />
          </div>-->
        </div>

        <!-- <div class="detailbox alt">
          <div class="infoline">
            <img
              src="@/assets/img/ico_info.svg"
              alt
            />
            <div class="desc">
              31 Aralık 2020 tarihine kadar mevcut izinlerin İleti Yönetim Sistemi
              üzerine aktarılması gerekmektedir. Bu tarihe kadar aktarılmayan
              izinler geçersiz sayılacaktır.
            </div> -->
        <!-- <div
              @click="SET_MODAL('ModalPreFaz'); SET_NOTIFY_REQUESTER_TYPE(1);"
              class="btn second"
            >
              <a>Beni Haberdar Et</a>
            </div>-->
        <!-- <div class="btn">
              <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir">Hemen Başvur</router-link>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Social from "../../genel/Social";

export default {
  name: "Homepage",
  components: { Social },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    },
  },
};
</script>

<style scoped>
.formcontainer {
  padding-top: 50px;
}

.madde p {
  margin-bottom: 10px;
}

.staticbox > div {
  padding: 1vw 1vw 0 1vw;
  margin: 0;
  width: 100%;
  text-align: left;
}

.staticbox.video > div {
  padding: 1vw 1vw 0 1vw;
  margin: 0;
  width: 100%;
  text-align: center;
}

ul li {
  display: list-item !important;
  list-style: square;
  margin: 15px 20px;
  justify-content: flex-start;
  line-height: 1.4;
  width: 100%;
}

.paket {
  color: #000;
  font-weight: 800;
  font-size: 1.1rem;
  text-align: left !important;
  background: #f3dace;
  padding-left: 13px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

p.note {
  background: #fdfbfa;
  border-radius: 15px;
  padding: 30px;
  margin: 30px auto 40px auto;
  box-shadow: 0 0 0 1px #dcc4b8;
  font-size: 1.2rem !important;
  font-weight: 400;
  line-height: 1.7;
}

p.note a,
p.note strong,
p.note b {
  font-size: 1.2rem !important;
  font-weight: 800;
}

h3 {
  margin-top: 5vh;
}
.staticbox {
  padding: 0 2vw 2vw 2vw;
}
.staticpage h1 {
  margin-bottom: 2vh;
  padding-bottom: 0;
}

.fiyatlar {
  width: 100%;
  margin: 3vh auto 2vh 0px;
  background: #fdfbfa;
  border-radius: 15px;
  overflow: hidden;
  font-size: 0.9rem;
  border: 2px solid #dcc4b8;
  box-shadow: 0 10px 32px -10px #00000040;
}

.tick {
  min-width: 85px !important;
  max-width: 85px !important;
}

.fiyatlar .nooption {
  color: #d1d1d1;
}
.fiyatlar .option {
  color: #68b313;
  font-size: 1.2rem;
}

.fiyatlar .ucretsiz {
  color: #68b313;
  font-weight: 800;
  font-size: 1rem;
  text-align: right !important;
}
.fiyatlar .ucretli {
  color: #000;
  font-weight: 800;
  text-align: right !important;
  font-size: 1.2rem;
}

.fiyatlar .ucretli,
.fiyatlar .ucretsiz {
  min-width: 65px !important;
  max-width: 65px !important;
  width: 65px !important;
}

.fiyatlar th {
  background: #f5e5dd;
  font-weight: 600;
  font-size: 0.8rem;
}
.fiyatlar .seviye,
.fiyatlar .verisayi {
  background: #dbefff;
  color: #000;
  font-weight: 600;
}

.fiyatlar .seviye td {
  height: 25px;
}

.fiyatlar td,
.fiyatlar th {
  height: 45px;
  min-width: 85px;
  max-width: 120px;
  padding: 7px;
  border: 1px solid #dcc4b8;
  text-align: center;
}
/* .fiyatlar tr td:first-child {
  font-weight: 600;
  text-align: left;
} */
</style>
